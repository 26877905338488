import "../styles/components/TeamCard.scss";
export default function TeamCard({
  position,
  image,
  name,
  study,
  about,
  email,
  phone,
  icon,
  joined,
  joinedDetails,
}) {
  return (
    <div
      className="tContainer"
      style={{
        backgroundImage: "url(/background.svg)",
        width: "318px",
        height: joined ? "420px" : "400px",
      }}
    >
      <div className="theader">
        <div
          className="theaderInside"
          style={{
            fontFamily: "Poppins sans-serif",
            fontWeight: "lighter",
          }}
        >
          {position}
        </div>
      </div>
      <div className="tImage">
        <div className="tImageHeader">
          <img src={image} alt={`icon${image}`} className="tImageInside" />
        </div>
        <div className="tImageHeaderIcon">
          <i className={icon}></i>
        </div>
      </div>
      <div className="tTitle" style={{ height: joined ? "12%" : "14%" }}>
        <span
          style={{
            fontFamily: "Poppins sans-serif",
            fontWeight: "bold",
          }}
        >
          {name}
        </span>
        <span>{study}</span>
      </div>
      <div className="tTitleOther" style={{ height: joined ? "14%" : "12%" }}>
        <span
          style={{
            fontSize: "14px",
          }}
        >
          <i className="fa-solid fa-phone me-2"></i>
          {`+91 ${phone}`}
        </span>
        <span
          style={{
            fontSize: "14px",
          }}
        >
          <i className="fa-solid fa-envelope me-2"></i>
          {email}
        </span>
        {joined ? (
          <span
            style={{
              fontSize: "14px",
            }}
          >
            <i className="fa-solid fa-briefcase me-2"></i>
            {joinedDetails}
          </span>
        ) : null}
      </div>
      <div className="tExtra">{about}</div>
    </div>
  );
}
