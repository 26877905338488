import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import the FontAwesomeIcon component
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"; // import the icons you need
import {} from "@fortawesome/free-brands-svg-icons"; // import the icons you need
import "../styles/components/header.scss";

import { customSelector, customDispatch } from "../app/hooks.ts";
import { useSelector, useDispatch } from "react-redux";
export default function Header() {
  const dispatch = useDispatch();
  const { isMenuEnabled, isDarkMode } = customSelector(
    (state) => state.actionCombined
  );
  return (
    <div className="official">
      <div className="officialOne">
        @Official Website of Kalimpong Himalayan Edu-Care Institution
      </div>
      <div className="officialTwo">
        <a
          className="officialTwoTitle"
          href="https://mail.google.com/mail/u/0/#inbox?compose=GTvVlcRwRCMgtJvQkhnmrwHfQtcSrkFVnXzDlnJgzHrMRmlKPDCtsfJqdKwbBgZTRKXSTskhNQSvg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{
              marginRight: 5,
            }}
          />
          principalkheci@gmail.com
        </a>
      </div>
    </div>
  );
}
