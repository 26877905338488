const downloadItems = {
  title: "Downloads",
  icon: "fa-solid fa-arrow-right me-2",
  items: [
    {
      header: "ASSIGNMENT (BA, MA)",
      link: "https://webservices.ignou.ac.in/assignments/",
    },
    {
      header: "ASSIGNMENT (X, XII)",
      link: "https://www.nios.ac.in/student-information-section/tutor-mark-assignment-status.aspx",
    },
    {
      header: "HALL TICKET",
      link: "https://ignouhall.ignou.ac.in/HallTickets/HALL0620/Hall0620.asp",
    },
    {
      header: "",
      link: "",
    },
  ],
  iconBanner: "fa-solid fa-download",
};

const resultItems = {
  title: "Results",
  icon: "fa-solid fa-arrow-right me-2",
  items: [
    {
      header: "BA, BSC, MA & Other's",
      link: "https://gradecard.ignou.ac.in/gradecard/",
    },
    {
      header: "D.ELED",
      link: "https://dled.nios.ac.in/attendancelogin/ResultISemlogin.aspx",
    },
    {
      header: "CLASS X",
      link: "https://old.nios.ac.in/nosresults/nos/oldmassec.htm",
    },
    {
      header: "CLASS XII",
      link: "https://old.nios.ac.in/nosresults/nos/oldmassec.htm",
    },
  ],
  iconBanner: "fa-solid fa-file",
};

const aboutData = {
  executiveFramework: [
    {
      position: "MD & Co-Founder",
      image: "https://kheci.org/faculty_member/rupesh.jpeg",
      icon: "fa-solid fa-user-tie",
      name: "Mr. Rupesh Kumar Singh",
      email: "principalkheci@gmail.com",
      phone: "9832368234",
      study: "M.S.W, MA Hindi, PGDCA, BLIS",
      about:
        "Principal & Co-Ordinator, R-Tech Computer Institute, Hatt Bazar Above Ganesh Mandir, Kalimpong, West Bengal, 734301.",
    },
    {
      position: "Vice President",
      image: "https://kheci.org/faculty_member/mukesh.webp",
      icon: "fa-solid fa-user-tie",
      name: "Mr. Mukesh Singh",
      email: "mukeshsingh722@gmail.com",
      phone: "9933025292",
      study: "M.SC, B.Ed, PGDCA",
      about:
        "Asistant Teacher at Gitabling Higher Secondary School, Kalimpong, West Bengal, 734301.",
    },
    {
      position: "Vice President",
      image: "https://kheci.org/faculty_member/download.png",
      icon: "fa-solid fa-user-tie",
      name: "Sushma Chettri",
      email: "xxxxxxxxxxxxxxxxxxxx",
      phone: "xxxxxxxxxx",
      study: "",
      about: "xxxxxxxxxxxxxxxxxxxx",
    },
    {
      position: "Secretary",
      image: "https://kheci.org/faculty_member/paras_sharma.jpg",
      icon: "fa-regular fa-id-badge",
      name: "Mr. Paras Sharma",
      email: "xxxxxxxxxxxxxxxxxxxx",
      phone: "9832039760",
      study: "MA, M.Ed",
      about:
        "Asistant Teacher at Saptashri Gyanpeeth School, Kalimpong, West Bengal, 734301.",
    },
    {
      position: "Vice- Secretary",
      image: "https://kheci.org/faculty_member/shima_ghimiray.jpg",
      icon: "fa-regular fa-id-badge",
      name: "Shima Ghimiray",
      email: "xxxxxxxxxxxxxxxxxxxx",
      phone: "9933741410",
      study: "",
      about: "xxxxxxxxxxxxxxxxxxxx",
    },
    {
      position: "Treasurer",
      image: "https://kheci.org/faculty_member/bikram.jpeg",
      icon: "fa-regular fa-id-badge",
      name: "Mr. Bikram Singh",
      email: "xxxxxxxxxxxxxxxxxxxx",
      phone: "9064759620",
      study: "DCA",
      about:
        "Former Asistant Teacher at R-Tech Computer Institute, Hatt Bazar Above Ganesh Mandir, Kalimpong, West Bengal, 734301.",
    },
    {
      position: "Member",
      image: "https://kheci.org/faculty_member/suman_gurung.jpg",
      icon: "fa-regular fa-id-badge",
      name: "Suman Gurung",
      email: "xxxxxxxxxxxxxxxxxxxx",
      phone: "8768656318",
      study: "MA, B.Ed",
      about:
        "Asistant Teacher at Kagay Higher Secondary School, Kalimpong, West Bengal, 734311.",
    },
    {
      position: "Member",
      image: "https://kheci.org/faculty_member/download.png",
      icon: "fa-regular fa-id-badge",
      name: "Shova Chettri",
      email: "xxxxxxxxxxxxxxxxxxxx",
      phone: "xxxxxxxxxx",
      study: "",
      about: "xxxxxxxxxxxxxxxxxxxx",
    },
    {
      position: "Member",
      image: "https://kheci.org/faculty_member/angel_lepcha.jpg",
      icon: "fa-regular fa-id-badge",
      name: "Angel Lepcha",
      email: "xxxxxxxxxxxxxxxxxxxx",
      phone: "7866099887",
      study: "BA",
      about: "xxxxxxxxxxxxxxxxxxxx",
    },
    {
      position: "Member",
      image: "https://kheci.org/faculty_member/kishore.jpeg",
      icon: "fa-regular fa-id-badge",
      name: "Mr. Kishor Chandra Sharma",
      email: "xxxxxxxxxxxxxxxxxxxx",
      phone: "xxxxxxxxxx",
      study: "MA, B.Ed",
      about:
        "Asistant Teacher at Indo Tibetan Higher Secondary School, Kalimpong, West Bengal, 734301.",
    },
    {
      position: "Member",
      image: "https://kheci.org/faculty_member/download.png",
      icon: "fa-regular fa-id-badge",
      name: "Mr. Gopal Sharma",
      email: "xxxxxxxxxxxxxxxxxxxx",
      phone: "xxxxxxxxxx",
      study: "",
      about: "xxxxxxxxxxxxxxxxxxxx",
    },
    {
      position: "Software Developer",
      image: "https://kheci.org/faculty_member/rishu.webp",
      icon: "fa-sharp fa-solid fa-code",
      name: "Mr. Rishu Chowdhary",
      email: "hi.10rishu@gmail.com",
      phone: "9836110678",
      study: "BBA, DCA",
      about:
        "Software Developer with knowledge of Java, Python, JavaScript, TypeScript, and many more library and framework. Former Operation Consultant at Uber India System PVT Ltd. See  https://github.com/hirishu10",
    },
  ],
  advisoryBoard: [
    {
      position: "Member",
      image: "https://kheci.org/faculty_member/rb_bhujel.jpg",
      icon: "fa-solid fa-user-shield",
      name: "Dr. R. B. Bhujel",
      email: "xxxxxxxxxxxxxxxxxxxx",
      phone: "xxxxxxxxxx",
      study: "",
      about: "Principal at Bijan Bari Degree College, Darjeeling 734201",
    },
    {
      position: "Member",
      image: "https://kheci.org/faculty_member/rajendra.jpg",
      icon: "fa-solid fa-user-shield",
      name: "Dr. Rajendra Yonzone",
      email: "yonzonebiosystematics@gmail.com",
      phone: "7477635397",
      study: "Ph.D, DCA, HORT(DIP)",
      about:
        "Assistant Professor at Department of Botany, Victoria Instution (College), Kolkata, West Bengal 700009",
    },
    {
      position: "Member",
      image: "https://kheci.org/faculty_member/download.png",
      icon: "fa-solid fa-user-shield",
      name: "Dr. Samuel Rai",
      email: "xxxxxxxxxxxxxxxxxxxx",
      phone: "xxxxxxxxxx",
      study: "",
      about:
        "Programme Co-ordinator, Darjeeling Krishi Vigyan, Utter Bangal Krishi Viswavidhyalaya. Kalimpong Darjeeling",
    },
    {
      position: "Member",
      image: "https://kheci.org/faculty_member/download.png",
      icon: "fa-solid fa-user-shield",
      name: "Mr. Basudegv Khadka",
      email: "xxxxxxxxxxxxxxxxxxxx",
      phone: "xxxxxxxxxx",
      study: "",
      about:
        "Trainign Associate. Darjeeling Krishi Vigyan Kendra UBKV Kalimpong, Dist: Darjeeling.",
    },
  ],

  facultyMember: [
    {
      position: "MD & Co-Founder",
      image: "https://kheci.org/faculty_member/rupesh.jpeg",
      icon: "fa-solid fa-user-tie",
      name: "Mr. Rupesh Kumar Singh",
      email: "principalkheci@gmail.com",
      phone: "9832368234",
      study: "M.S.W, MA Hindi, PGDCA, BLIS",
      about:
        "Principal & Co-Ordinator, R-Tech Computer Institute, Hatt Bazar Above Ganesh Mandir, Kalimpong, West Bengal, 734301.",
      joined: false,
      joinedDetails: "",
    },
    {
      position: "Vice President",
      image: "https://kheci.org/faculty_member/mukesh.webp",
      icon: "fa-solid fa-user-tie",
      name: "Mr. Mukesh Singh",
      email: "mukeshsingh722@gmail.com",
      phone: "9933025292",
      study: "M.SC, B.Ed, PGDCA",
      about:
        "Asistant Teacher at Gitabling Higher Secondary School, Kalimpong, West Bengal, 734301.",
      joined: false,
      joinedDetails: "",
    },
    {
      position: "Faculty",
      image: "https://kheci.org/faculty_member/computer_teacher/anshu.jpg",
      icon: "fa-solid fa-user",
      name: "Miss. Anshu Thakur",
      email: "xxxxxxxxxxxxxxxxxxxx",
      phone: "7866922662",
      study: "A.D.C.A",
      about:
        "Asistant Teacher at R-Tech Computer Institute, Hatt Bazar Above Ganesh Mandir, Kalimpong, West Bengal, 734301.",
      joined: true,
      joinedDetails: "16th Mar 2023 - Till Date",
    },
    {
      position: "Faculty",
      image: "https://kheci.org/faculty_member/computer_teacher/pratiksha.jpg",
      icon: "fa-solid fa-user",
      name: "Miss. Pratiksha Tamang",
      email: "xxxxxxxxxxxxxxxxxxxx",
      phone: "8159074209",
      study: "D.C.A",
      about:
        "Asistant Teacher at R-Tech Computer Institute, Hatt Bazar Above Ganesh Mandir, Kalimpong, West Bengal, 734301.",
      joined: true,
      joinedDetails: "13th Feb 2024 - Till Date",
    },
    {
      position: "Ex-Faculty",
      image: "https://kheci.org/faculty_member/computer_teacher/yankit.jpg",
      icon: "fa-solid fa-user",
      name: "Miss. Yankit Lepcha",
      email: "xxxxxxxxxxxxxxxxxxxx",
      phone: "8370856634",
      study: "D.C.A",
      about:
        "Asistant Teacher at R-Tech Computer Institute, Hatt Bazar Above Ganesh Mandir, Kalimpong, West Bengal, 734301.",
      joined: true,
      joinedDetails: "-",
    },
  ],
};

export { downloadItems, resultItems, aboutData };
