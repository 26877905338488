import { Helmet } from "react-helmet";
import "../styles/about/About.scss";
import TeamCard from "../components/TeamCard";
import { aboutData } from "../assets/data/index.js";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import EditableNavbar from "../components/EditableNavbar";
import Header from "../components/Header";

export default function About() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="designed & developed by Kalimpong Himalayan Edu-Care Institution"
        />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
          integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
        <title>Management</title>
      </Helmet>
      <Header />
      <Navbar active="management" />
      <EditableNavbar active="management" />
      <div
        style={{
          width: "100%",
          height: "100px",
          // backgroundColor: "red",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <h2
          style={{
            fontFamily: "system-ui",
          }}
        >
          "Education is the movement from darkness to light"-Allan Bloom
        </h2>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: "500px",
          // backgroundColor: "red",
          // backgroundImage: "url(https://kheci.org/pic17.jpg)",
          // backgroundImage:
          // "url(https://kheci.org/faculty_member/rtech_flex.png)",
          // backgroundSize: "cover",
          // backgroundSize: "100% 100%",
          // backgroundRepeat: "no-repeat",
        }}
      >
        <img
          src="https://kheci.org/faculty_member/rtech_flex.png"
          style={{
            display: "block",
            width: "100%",
            height: "auto",
          }}
          alt="Kheci-Members"
        />
      </div>
      <div className="aimContainer">
        <div
          className="aimContainerFirst"
          style={
            {
              // backgroundImage: "url(https://kheci.org/pic17.jpg)",
            }
          }
        >
          <p className="visionsPara">
            We are providing the complete IT education for the students those
            who deserves on special help. Our main objective is to promote the
            complete education in the field of IT Sector and to make an able to
            every student to face the problems in the Super Modern Information
            Techno World in the forth coming days. We are giving priorities to
            some our main Visions.
          </p>
          <br />
          <ul className="visions">
            <li>
              To provide better education to our school dropout children,
              unempolyed youths etc.
            </li>
            <li>
              To provide efficient computer and allied educational facilities.
            </li>
            <li>
              To disseminate latest hi-tech educational information throught the
              region.
            </li>
            <li>
              To make people of Hill Area well educated by providing Distance
              Education.
            </li>
            <li>
              To develop strong referrals & linkages regarding all activities
              for their empowerment, safety & upliftment.
            </li>
          </ul>
        </div>
      </div>

      {/*  */}
      <div className="team">
        Team's <i className="fa-solid fa-people-group ms-2"></i>
      </div>
      <div className="teamContainerOne">
        <div className="teamContainerOneTitle">
          <h4>Executive Frame Work</h4>
        </div>
        <div className="teamContainerOneBody">
          {aboutData?.executiveFramework.map((item, index) => (
            <TeamCard
              key={index}
              position={item?.position}
              image={item?.image}
              name={item?.name}
              study={item?.study}
              about={item?.about}
              email={item?.email}
              icon={item?.icon}
              phone={item?.phone}
            />
          ))}
        </div>
      </div>
      {/*  */}
      <div className="teamContainerTwo">
        <div className="teamContainerTwoTitle">
          <h4>
            <i className="fa-solid fa-user-shield ms-2"></i> Advisory Board
          </h4>
        </div>
        <div className="teamContainerTwoBody">
          {aboutData?.advisoryBoard.map((item, index) => (
            <TeamCard
              key={index}
              position={item?.position}
              image={item?.image}
              name={item?.name}
              study={item?.study}
              about={item?.about}
              email={item?.email}
              icon={item?.icon}
              phone={item?.phone}
              joined={false}
              joinedDetails={""}
            />
          ))}
        </div>
      </div>
      {/*  */}
      <div className="teamContainerTwo">
        <div className="teamContainerTwoTitle">
          <h4>
            <i className="fa-solid fa-computer"></i> Computer Faculty
          </h4>
        </div>
        <div className="teamContainerTwoBody">
          {aboutData?.facultyMember.map((item, index) => (
            <TeamCard
              key={index}
              position={item?.position}
              image={item?.image}
              name={item?.name}
              study={item?.study}
              about={item?.about}
              email={item?.email}
              icon={item?.icon}
              phone={item?.phone}
              joined={item?.joined}
              joinedDetails={item?.joinedDetails}
            />
          ))}
        </div>
      </div>
      {/*  */}
      <Footer />
    </div>
  );
}
